@use "../../style/mixins";
@use "../../style/variables" as *;


.header {
    width: 100%;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.nav {
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mixins.breakpoint-up(medium){
        justify-content: initial;
        column-gap: 3.5rem;
    }

    .nav__logo {
        margin-right: 1rem;
    }

    .nav__menu {
        transition: .5s;

        @include mixins.breakpoint-down(medium){
            position: fixed;
            background-color: $bodyClr;
            top: 0;
            right: -100%;
            width: 100%;
            height: 100%;
            padding: 6rem 0rem;
        }

        &.show-menu {
            right: 0;
            transition: right .5s ease;
        }
    }

    .nav__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 4rem;

        @include mixins.breakpoint-up(medium){
            flex-direction: row;
            column-gap: calc(2rem + 5%);
        }
    }

    .nav__link {
        color: $titleClr;
        font-weight: $weightMd;
        text-transform: uppercase;
        font-size: $fontSlg;
        transition: all .3s ease;
        
        @include mixins.breakpoint-up(medium){
            font-size: 1.3rem
        }

        @include mixins.breakpoint-up(large) {
            font-size: $fontSlg;
        }

        &.active-link {
            color: $firstClr;
        }

        &:hover {
            text-decoration: underline;
            transition: .3s;
        }
    }

    .nav__close {
        position: absolute;
        top: .9rem;
        right: 1.25rem;
        cursor: pointer;
        background: transparent;

        @include mixins.breakpoint-up(medium) {
            display: none;
        }
    }

    .nav__btns {
        display: flex;
        align-items: center;
        column-gap: 1rem;

        @include mixins.breakpoint-up(medium) {
            margin-left: auto;
            column-gap: 2rem;
        }

        .nav__toggle,
        .nav__shop,
        .nav__search {
            cursor: pointer;
            background: transparent;
        }

        .nav__shop img {
            @include mixins.breakpoint-up(medium) {
                width: 40px;
                height: 40px;
            }
        }

        .nav__toggle {
            @include mixins.breakpoint-up(medium) {
                display: none;
            }
        }
    }

    .nav__search-form {
        position: absolute;
        top: 125%;
        right: 5%;
        width: 90%;
        height: 1rem;
        display: flex;
        align-items: center;
        transform: scaleY(0);
        transform-origin: top;
        transition: .2s;

        @include mixins.breakpoint-up(large){
            width: 40rem;
            top: 120%;
            right: 17%;
        }

        &.active-form {
            transform: scaleY(1);
        }

        .nav__search-input {
            height: 100%;
            width: 100%;
            font-size: $fontMd;
            color: $bodyClr;
            padding: 1rem;
            text-transform: none;
        }
    }
}

.active-link {
    text-decoration: underline;
}

.scroll-header {
    background: $bodyClr;
    box-shadow: 0 1px 4px hsla(0, 4%, 15%, .10);
}