@use "../../style/mixins";
@use "../../style/variables" as *;

.testimonial {
    &__container {
        display: grid ;
        row-gap: 2rem;

        @include mixins.breakpoint-up(small){
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
        }
    
        @include mixins.breakpoint-up(large){
            column-gap: 8rem;
            padding-bottom: 4rem;
        }
    
        @include mixins.breakpoint-up(xlarge){
            column-gap: 10rem;
        }
    }

    .testimonial__slider {
        margin-left: initial;
        margin-right: initial;

        .slider__wrapper {
            display: flex;
            position: relative;
            height: 420px;
            overflow: hidden;

            .testimonial__slide {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: .3s;
                opacity: 0;

                &.active-slide {
                    opacity: 1;
                    transform: translateX(0);
                }

                &.prev-slide {
                    transform: translateX(-100%);
                }

                &.next-slide {
                    transform: translateX(100%);
                }

                .testimonial__quote {
                    display: inline-flex;
                    background-color: transparent;
                    padding: .5rem .75rem;
                    box-shadow: 0 4px 12px #3333334d;
                    margin-bottom: $mb-1;
                }

                .testimonial__description {
                    margin-bottom: $mb-1;
                    font-weight: $weightMd;
                    line-height: 1.3;
                    color: $textClr;
                }

                .testimonial__date {
                    font-size: $fontMd;
                    font-weight: $weightMd;
                    margin-bottom: $mb-1;
                    color: $textClr;
                }

                .testimonial__profile {
                    display: flex;
                    align-items: center;
                    column-gap: 1rem;
                    margin-bottom: $mb-2;
                    font-weight: $weightMd;
                    color: $textClr;

                    &-img {
                        width: 150px;
                        height: 150px;
                        border-radius: 50%;
                    }

                    &-data {
                        display: flex;
                        flex-direction: column;
                        row-gap: 1rem;
                    }

                    &-detail {
                        font-size: $fontSm;
                    }
                }
            }
        }

        .slider__button-next, 
        .slider__button-prev {
            background: transparent;
            box-shadow: 0 4px 12px #3333334d;
            padding: .25rem;
        }

        .slider__button-prev {
            margin-right: 2rem;
        }

        .slider__button-next {
            margin-left: 2rem;
        }
    }

    .testimonial__images {
        position: relative;

        .testimonial__overlay {
            height: 260px;
            width: 170px;
            background-color: $firstClr;
            margin-left: auto;

            @include mixins.breakpoint-down(small){
                height: 190px;
            }

            @include mixins.breakpoint-up(medium){
                width: 260px;
                height: 370px;
            }

            @include mixins.breakpoint-up(large){
                height: 450px;
                width: 300px;
            }
        }

        .testimonial__img {
            position: absolute;
            width: 180px;
            right: 1rem;
            top: 1rem;
            box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

            @include mixins.breakpoint-down(small){
                width: 200px;
            }
            @include mixins.breakpoint-up(medium){
                width: 260px;
            }
            @include mixins.breakpoint-up(large) {
                width: 300px;
                right: 2rem;
                top: 2rem;
            }
        }
    }
}

