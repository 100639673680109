// COLORS
$bodyClr: #1f1f1f;
$firstClr: #db6c03;
$btnClr: #3d3d3d;
$btnClrLg: #474747;
$titleClr: #f2f2f2;
$textClr: #bfbfbf;
$textClrLight: #8c8c8c;
$containerClr: #292929;
$borderCrl: #333333;

// FONTS
$fontXlg: 4rem;
$fontLg: 3rem;
$fontSlg: 1.5rem;
$fontMd: 1rem; //normal-font-size: 1rem;
$fontSm: .875rem; //small-font-size

// FONT WEIGHTS
$weightMd: 500;
$weightLg: 700;
$weightXlg: 900;

// MARGIN BOTTOM
$mb-0-5: .5rem;
$mb-0-75: .75rem;
$mb-1: 1rem;
$mb-1-5: 1.5rem;
$mb-2: 2rem;
$mb-2-5: 2.5rem;
$mb-3: 3rem;
