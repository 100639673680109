@use "./mixins";
@use "./variables" as *;

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
html{
    font-size: 100%;
    scroll-behavior: smooth;
    width: 100%; 
    min-height: 100%; 
}
body {
    width: inherit; 
    min-height: inherit; 
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    background: $bodyClr;
    overflow-x: hidden;
}
img {
    max-width: 100%;
    display: block;
}
ul{
    list-style: none;
}
li {
    list-style-type: none;
}
a {
    text-decoration: none;
}
h1 {
    font-size: 2.5rem;
    @include mixins.breakpoint-up(medium){
        font-size: 3rem;
    }
}
h2 {
    font-size: 2rem;
    @include mixins.breakpoint-up(medium){
        font-size: 2.3rem;
    }
}
h3 {
    font-size: 1.5rem;
    @include mixins.breakpoint-up(medium){
        font-size: 1.8rem;
    }
}

button{
    cursor: pointer;
    border: none;
    outline: none;
}

// REUSABLE CLASSES
.container {
    max-width: 1024px;
    margin-left: $mb-1-5;
    margin-right: $mb-1-5;

    @include mixins.breakpoint-down(small){
        margin-left: $mb-1;
        margin-right: $mb-1;
    }

    @include mixins.breakpoint-up(xlarge) {
        margin-left: auto;
        margin-right: auto;
    }
}

.main{
    overflow: hidden;
}

.section{
    padding: 6.5rem 0 1rem;

    &__title{
        position: relative;
        margin-bottom: $mb-3;
        color: $textClr;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: center;

        &::before{
            content: '';
            position: absolute;
            top: -1rem;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 10rem;
            height: 1px;
            background-color: $firstClr;
        }
    }
}

.button {
    display: inline-block;
    background-color: $btnClr;
    text-transform: uppercase;
    color: #fff;
    padding: 1.25rem 2rem;
    font-weight: $weightMd;
    transition: .5s;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(255,255,255,0.1);
        transition: all 0.3s;
    }
    &:hover::before {
        opacity: 0 ;
        transform: scale(0.5,0.5);
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        transition: all 0.3s;
        border: 1px solid rgba(255,255,255,0.5);
        transform: scale(1.2,1.2);
    }

    &:hover::after {
        opacity: 1;
        transform: scale(1,1);
    }
}

.button--gray {
    background-color: $textClr;
    color: $btnClrLg;

    &:hover {
        background-color: #a1a1a1;
        color: $titleClr;
    }
}

.button--small {
    padding: .9rem 1.5rem;
}
