@use "../../style/mixins";
@use "../../style/variables" as *;

.featured {
    margin-bottom: 2rem;
    // overflow-y: hidden;

    &__container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        row-gap: 2.5rem;

        @include mixins.breakpoint-up(small){
            justify-content: center;
            align-items: flex-start;
            gap: 1.5rem;
        }

        @include mixins.breakpoint-up(large){
            padding-top: 2rem;
        }

        .featured__card {
            position: relative;
            width: 20rem;
            margin: 0 auto;
            text-align: center;
            background-color: #fff;
            padding-top: 2rem;
            border: 1px solid $borderCrl;
            overflow-y: hidden;
            transition: .3s;
            box-shadow: 0px 5px 10px 0px rgba(255,255,255,0.7);

            &:hover {
                box-shadow: 0 12px 32px hsla(0, 0%, 20%, .1);
                padding: 2rem 0 3rem 0;
            }

            &:hover .featured__button {
                transform: translateY(0);
                opacity: 1;
            }

            &:hover .fetured__price {
                margin-bottom: $mb-1-5;
            }

            .featured__tag {
                background-color: $firstClr;
                padding: .5rem 1rem;
                color: #fff;
                text-transform: uppercase;
                font-size: $fontSm;
                font-weight: $weightMd;
                position: absolute;
                transform: rotate(-90deg);
                left: -1rem;
                top: 2rem;
            }

            .featured__img {
                height: 214px;
                margin-bottom: 1rem;
            }

            .featured__title,
            .featured__price {
                font-size: 1.5rem;
                font-weight: $weightMd;
            }

            .featured__title {
                text-transform: uppercase;
                margin-bottom: $mb-0-75;
            }

            .featured__price {
                display: block;
                color: $firstClr;
                transition: .3s;
            }

            .featured__button {
                font-size: $fontSm;
                transform: translateY(1rem);
                opacity: 0;
            }
        }
    }
}
