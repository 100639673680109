@use "../../style/mixins";
@use "../../style/variables" as *;

.new {
    margin-bottom: 2rem;
    overflow: hidden;

    &__container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
        row-gap: 2rem;

        @include mixins.breakpoint-up(small){
            justify-content: center;
            align-items: flex-start;
            column-gap: 1rem;
        }

        @include mixins.breakpoint-up(large){
            padding: 2rem 0;
            column-gap: 1rem;
        }

        .new__card {
            width: 15rem;
            margin: 0 auto;
            position: relative;
            text-align: center;
            background-color: white;
            padding-top: 2rem;
            border: 1px solid $borderCrl;
            overflow: hidden;
            transition: all .5s ease-in-out;
            border-radius: 5px;

            &:hover {
                box-shadow: 0px 5px 10px 0px rgba(255,255,255,0.7);
                padding: 2rem 0;
            }

            &:hover .new__price {
                margin-bottom: $mb-1-5;
            }
        
            &:hover .new__social {
                transform: translateY(0);
                opacity: 1;
            }

            .new__tag {
                background-color: $firstClr;
                padding: .5rem 1rem;
                color: white;
                text-transform: uppercase;
                font-size: $fontMd;
                font-weight: $weightMd;
                position: absolute;
                top: 1rem;
                right: 1rem;
                transition: .3s;
            }

            .new__img {
                height: 200px;
                margin: 0 auto;
                margin-bottom: $mb-1;
            }

            .new__title,
            .new__price {
                font-size: 1.25rem;
                font-weight: $weightMd;

                @include mixins.breakpoint-up(medium){
                    font-size: $fontMd;
                }
            }

            .new__title {
                text-transform: uppercase;
                margin-bottom: $mb-0-75;
            }

            .new__price{
                display: block;
                color: $firstClr;
                transition: .3s;
            }
            .new__social{
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 1.5rem;
                transform: translateY(1rem);
                opacity: 0;
                transition: all .5s ease-in-out;

                &-link {
                    font-size: 1.25rem;
                    color: #595959;
                }
            }
        }
        
    }
}